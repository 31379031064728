import BaseService from "./base-service";
import type { AddCartItemViewModel, CartItemViewModel, DeleteCartItemViewModel } from "@/types/models/cart/cart-item-view-model";

class CartService extends BaseService {
    async getShoppingCart(customerId: Nullable<number>) {
        return await this.get<CartItemViewModel[]>('/cart/getShoppingCart', { params: { customerId } });
    }

    async addProductToCart(model: AddCartItemViewModel) {
        await this.post('/cart/addProductToCart', model);
    }

    async deleteProductFromCart(model: DeleteCartItemViewModel) {
        await this.post('/cart/deleteProductFromCart', model);
    }
}

export default new CartService();